import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import Banner from "./Banner";
import styled from "styled-components";
import CommonButton from "../common/Button";
import Text from "../common/Text";
import Icon from "../common/Icon/Icon";
import {ICONS} from "@repo/utils/constants";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {getCssSizeValue, getFontSize, getHtmlFromRedactorField, removeTags, setCssVars} from "@repo/utils";
import Image from "../common/Image";
import {getCardData} from "../common/CardsSlider/Card";
import Tag from "../common/Tag";
import Link from "../common/Link";
import {useRouter} from "next/router";
import {isDesktop} from "react-device-detect";
import {useSearchParams} from "next/navigation";

const Wrapper = styled(Banner)`
    --area-color: var(--primary-color);
    --border-color: var(--tertiary-color);
    background-color: var(--area-color);
    min-height: 85svh;
    padding-top: ${getCssSizeValue(100, BREAKPOINTS.MOBILE)};
    .icon{
        width: 15px;
        max-width: unset;
    }
    
    .small-text{
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        color: var(--secondary-color) !important;
        letter-spacing: normal;
        line-height: normal;
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        padding-top: ${getCssSizeValue(170, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        padding-top: ${getCssSizeValue(180, BREAKPOINTS.DESKTOP)};
        padding-bottom: ${getCssSizeValue(180, BREAKPOINTS.DESKTOP)};
        min-height: 100svh;
        .small-text{
            font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
        }
    }
    
`

const Button = styled(CommonButton)`
    margin-bottom: ${getCssSizeValue(80, BREAKPOINTS.MOBILE)};
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        margin-bottom: 0;
	}
`

const HeaderWrapper = styled.div`
    display: flex;
    margin-top: auto;
    margin-bottom: ${getCssSizeValue(45, BREAKPOINTS.MOBILE)};
    overflow: visible;
    justify-content: start;
    flex-direction: column-reverse;
    row-gap: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};
    em{
        font-weight: ${({ theme }) => theme?.fontWeight?.light || 200};
    }
    
    .image{
        width: ${getCssSizeValue(172, BREAKPOINTS.MOBILE)};
        height: ${getCssSizeValue(122, BREAKPOINTS.MOBILE)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        --image-height: ${getCssSizeValue(308, BREAKPOINTS.DESKTOP)};
        margin-bottom: unset;
        justify-content: space-between;
        height: 100%;
        flex-direction: row;
        align-items: end;
    
        .image{
            width: ${getCssSizeValue(437, BREAKPOINTS.DESKTOP)};
            height: var(--image-height);
        }
        
        .header{
            transform: translateY(calc(var(--image-height) * -0.5  + 50%));
        }
    }
`

const TagsWrapper = styled.div`
    display: flex;
    gap: ${getCssSizeValue(15, BREAKPOINTS.DESKTOP)};
    flex-wrap: wrap;
    font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
    .line-break{
        width: 100%;
    }
    
    .header{
        margin-bottom: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        max-width: ${getCssSizeValue(485, BREAKPOINTS.DESKTOP)};

        .tag-header{
            //margin-bottom: ${getCssSizeValue(26, BREAKPOINTS.DESKTOP)};
        }
    }
`


const TagsBanner = forwardRef(function TagsBanner({ data, tags, hideBackToAllServices, backToPrevPage = false }, fref){
    const BannerRef = useRef()
    const router = useRouter();
    const header = getHtmlFromRedactorField(data?.header || 'TagsBanner')
    const backButton = data?.backButton || 'Back'
    const backLink = data?.backLink || ''
    const tagHeader = tags?.header || ''
    const tagData = tags?.data || []
    const tagPrefix = tags?.prefix || ''
    const { icon } = getCardData(data.card)
    const searchParams = useSearchParams()
    const origin = searchParams.get('origin')

    const hasIcon = icon.url.length > 0
    const [area, border] = useMemo(() => [
        data?.areaColor || 'var(--primary-color)',
        data?.borderColor || 'var(--tertiary-color)'
    ],[data])

    const handleBack = useCallback(() => {
        let destination
        if(origin){
             destination = origin.replace('-id-','#')
        } else {
             destination = backLink
        }
        return router.push(destination, destination, {
            locale: router.locale
        })
    },[router,backLink, origin])

    useEffect(() => {
        setCssVars(BannerRef.current, {
            'area-color': area,
            'border-color': border
        })
    }, [area, border]);

    useImperativeHandle(fref, () => BannerRef.current, [])
    return (
        <Wrapper ref={BannerRef}>
            {/*{!hideBackToAllServices && !backToPrevPage && (*/}
            {/*    <BackButtonWrapper>*/}
            {/*        <Button as="link" href={backLink} variant="unstyled" className="small-text">*/}
            {/*            <Icon name={ICONS.BUTTON_ARROW_LEFT} />*/}
            {/*            {backButton}*/}
            {/*        </Button>*/}
            {/*    </BackButtonWrapper>*/}
            {/*)}*/}
            {/*{backToPrevPage && (*/}
            {/*    <BackButtonWrapper>*/}
            {/*        <Button*/}
            {/*            as="button"*/}
            {/*            onClick={() => router.back()}*/}
            {/*            variant="unstyled"*/}
            {/*            className="small-text"*/}
            {/*        >*/}
            {/*            <Icon name={ICONS.BUTTON_ARROW_LEFT} />*/}
            {/*            {backButton}*/}
            {/*        </Button>*/}
            {/*    </BackButtonWrapper>*/}
            {/*)}*/}
            <Button
                onClick={() => handleBack()}
                variant="unstyled"
                className="small-text"
            >
                <Icon name={ICONS.BUTTON_ARROW_LEFT} />
                {backButton}
            </Button>
            <HeaderWrapper>
                <Text as="div" className="header" dangerouslySetInnerHTML={header}/>
                {hasIcon && (
                    <Image
                        src={icon.url}
                        focalPoint={isDesktop ? {x: 0.5, y: 0.5} : {x: 0, y: 0.5}}
                        alt={icon.alt}
                        fit="contain"
                        priority
                    />
                )}
            </HeaderWrapper>
            <TagsWrapper>
                <Text className="small-text tag-header">
                    {tagHeader}
                </Text>
                <div className="line-break"/>
                {tagData.map((tag, index) => {
                    const id = tag?.id || index
                    const name = removeTags(tag?.name || 'Tag', ['em', 'br', 'p'])
                    const slug = `/${tagPrefix}${tag?.slug || ''}`.trim()
                    return (
                        <React.Fragment key={name}>
                            <Tag as={Link} href={slug} accentColor={border}>{name}</Tag>
                        </React.Fragment>
                    )
                })}
            </TagsWrapper>
        </Wrapper>
    );
})

export default TagsBanner;
