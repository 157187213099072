import Head from "next/head";
import { renderMetaTags } from 'react-datocms';

export default function Seo({ data = [] }) {
    const metaTags = data?._seoMetaTags || []

    return (
        <Head>
            {renderMetaTags(metaTags)}
            <meta name="robots" content="index, follow"/>
        </Head>
    )
}