import styled from 'styled-components';
import Logo from '@/components/Menu/shared/Logo';
import {
    Button,
    LetsTalkButton,
    Link,
    StoryButton,
    useHideTopBarOnScroll,
    useIsMobile,
    usePageTransitionContext,
    useScrollContext,
    useSpecifyColor
} from "@repo/ui";
import {getCssSizeValue} from "@repo/utils";
import {BREAKPOINTS} from "@repo/ui/themeSettings";
import { motion} from "framer-motion";
import {useMemo} from "react";
import {CURSOR_TYPES} from "@repo/utils/constants";
import LanguageChange from "@/components/Menu/shared/LanguageChange";
import {useRouter} from "next/router";

const StyledTopRow = styled(motion.div)`
	z-index: 99;
	display: flex;
	justify-content: space-between;
	width: 100%;
    align-items: center;
    padding-top: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
    padding-bottom: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
    position: fixed;
    transition: all .3s;
    background: transparent;
    transform: translateY(-100%);
    
	@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        padding-top: ${getCssSizeValue(50, BREAKPOINTS.TABLET)};
        padding-bottom: ${getCssSizeValue(15, BREAKPOINTS.TABLET)};
	}
`;

const StyledRightPartWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${getCssSizeValue(20, BREAKPOINTS.DESKTOP)};
    margin-right: ${getCssSizeValue(10, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        margin-right: ${getCssSizeValue(10, BREAKPOINTS.DESKTOP)};
    }
`

const StyledContactButtonWrapper = styled.div`

`

const StyledLogoWrapper = styled.div`
	z-index: 1000;
`;

const StyledSingleNavItem = styled(Button)`
    text-transform: lowercase;
    font-weight: ${props => props.theme.fontWeight.semiBold};
    color: var(--secondary-color);
`

const TopRow = ({ firstMenuItem, contactButtonText, contactButtonLink, menuItems = [] }) => {
    const bgColor = useSpecifyColor()
    const contactButtonLinkSlug = contactButtonLink ? `/${contactButtonLink?.slug}` : '';
    const { asPath } = useRouter()
    const normalizedPath = useMemo(() => asPath === "/" ? "home" : asPath, [asPath])
    const { isProjectToProjectOutsideMenuRouteChangeState } = usePageTransitionContext()
    const router = useRouter()
    const { instance: { __lenis } } = useScrollContext()
    const isMobile = useIsMobile()

    const handleContactClick = () => {
        if (router.asPath.includes('contact')) {
            __lenis.scrollTo(0)
        } else {
            router.push(contactButtonLinkSlug, contactButtonLinkSlug, { scroll: false })
        }
    }

    useHideTopBarOnScroll()

    return (
        <StyledTopRow
            $bgColor={bgColor}
            id="menu-top-row"
            className="menu-top-row px-main"
        >
            <StyledLogoWrapper>
                <Logo firstMenuItem={firstMenuItem}/>
            </StyledLogoWrapper>
            <StyledRightPartWrapper>
                {menuItems.map((item) => {
                        const id = item?.id || ''
                        const text = item?.link?.menuContext?.previewText || ''
                        const slug = item?.link?.slug || ''
                        const isSlugHome = slug === 'home'
                        const href = isSlugHome ? '/' : `/${slug}`
                        const isActiveRoute = normalizedPath.includes(slug)
                        const isPleoGroupOptionHiddenOnMobile = isSlugHome && isMobile

                        return !isPleoGroupOptionHiddenOnMobile && (
                            <Link href={href} key={id}>
                                <StyledSingleNavItem
                                    variant='unstyled'
                                    key={id}
                                    data-cursor={CURSOR_TYPES.LINK}
                                    className={`underline-animation ${isActiveRoute ? 'active' : ''}`}
                                >
                                    {text}
                                </StyledSingleNavItem>
                            </Link>
                        )
                })}
                <StoryButton/>
                <LetsTalkButton onClick={handleContactClick} pleo="group">
                    {contactButtonText}
                </LetsTalkButton>
                <LanguageChange />
            </StyledRightPartWrapper>
        </StyledTopRow>
    );
};

export default TopRow;
