import { Vector2 } from 'three';

export const blurDataURL = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
export const SCALE_MULTIPLIER = 0.01;
export const CMS_ELEMENT_TYPENAME = '__typename';

export const ENVIRONMENTS = {
    dev: 'development',
    prod: 'production',
}

///CURSOR


export const DOT_SIZE = 10; //pixels
export const AFTER_SIZE = DOT_SIZE / 2;
export const CURSOR_EVENT = 'mousemove';
export const CURSOR_ATTR = 'data-cursor';
export const BLOB_RADIUS = SCALE_MULTIPLIER * 25;
//three js units of measure (originally meters)
//with scaleMultiplier = 0.01, 1 stands for  roughly 100px
export const CURSOR_TYPES = {
    BLOB: 'blob',
    LINK: 'link',
    LINK_LOWERCASE: 'link-lowercase',
    BUTTON: 'button',
    BUTTON_WITHOUT_BORDER: 'button-without-border',
    ELEVATOR: 'elevator-bar',
    CIRCLE: 'circle',
    CHIP: 'chip',
    CHIP_LEFT: 'chip-left',
    IMAGE: 'image',
    HIDE: 'hide',
    IMAGE3D: 'image3D',
    TEXT3D: 'text3D',
    THEME_CHANGE: 'theme-change',
    FULL_CIRCLE: 'full-circle',
    ROUNDED_OUTLINE: 'rounded-outline',
    STICKY: 'sticky',
    HTML: 'html',
    SLIDER: 'slider',
    SLIDER_OPEN: 'slider_open',
    MARQUEE: 'marquee',
    MARQUEE_EXPANDED: 'marquee_expanded',
    CHECK: 'check',
    CLOSE: 'close',
    PILL: 'pill',
    SOON: 'soon'
};
export const CURSOR_INITIAL_TARGET = {
    el: null,
    type: CURSOR_TYPES.BLOB,
    blobScale: 1,
    position: new Vector2(0, 0),
    color: 'secondary',
    size: {
        width: AFTER_SIZE,
        height: AFTER_SIZE,
    },
    scale: 1
};

///CURSOR

export const ICONS = {
    LIST: 'list',
    TILES: 'tiles',
    WAVE: 'wave',
    SHARPWAVE: 'sharp-wave',
    CIRCULARWAVE: 'circular-wave',
    MEDIUMWAVE: 'medium-wave',
    HIGHWAVE: 'high-wave',
    ARROWUP: 'arrow-up',
    ARROWDOWN: 'arrow-down',
    ARROWRIGHT: 'arrow-right',
    PLEONIANSRECRUIT: 'pleonians-recruit',
    PLEO_LOGO_DESIGN: 'pleo-logo-design',
    PLEO_LOGO_DIGITAL: 'pleo-logo-digital',
    PLEO_LOGO_METHOD: 'pleo-logo-method',
    PLEO_LOGO_EXPO: 'pleo-logo-expo',
    PLEO_LOGO_GROUP: 'pleo-logo-group',
    WAVY_CIRCLE: 'wavy-circle',
    HOME_LINE: 'home-line',
    HOW_WE_CREATE_STORY_ARROW_DOWN_DESKTOP: 'how-we-create-story-arrow-down-desktop',
    HOW_WE_CREATE_STORY_ARROW_DOWN_MOBILE: 'how-we-create-story-arrow-down-mobile',
    ROCKET: 'rocket',
    WE_ARE_PLEONIANS_TAG: 'we-are-pleonians-tag',
    BUTTON_ARROW_RIGHT: 'button-arrow-right',
	BUTTON_ARROW_LEFT: 'button-arrow-left',
    SERVICE_CIRCLE_PART_BORDER: 'service-circle-part-border',
    AREAS_OF_EXPERTISE_PIECE: 'areas-of-expertise-piece',
    LONG_THIN_ARROW_LEFT: 'long-thin-arrow-left',
    LONG_THIN_ARROW_RIGHT: 'long-thin-arrow-right',
    SHORT_THIN_ARROW_LEFT: 'short-thin-arrow-left',
    SHORT_THIN_ARROW_RIGHT: 'short-thin-arrow-right',
    CROSS: 'cross',
    PLEO_DESIGN_MENU_LOGO: 'pleo-design-menu-logo',
    PLEO_GROUP_MENU_LOGO: 'pleo-group-menu-logo',
    FEATURED_PROJECTS: 'featured-projects',
    FEATURED_PROJECTS_PL: 'featured-projects-pl',
    SOUND_ON: 'sound-on',
    SOUND_OFF: 'sound-off',
    THICK_WAVE: 'thick-wave',
    PRELOADER: 'preloader',
    SOON_MASK: 'soon-mask'
};

//:TODO poprawić struture projektu (DATO + kod) żeby nie było w komponentach biblioteki typów z digitala lub designu
export const CONTENT_TYPES = {
    PROJECTS_GRID: 'CommonProjectsGridRecord',
    // TWO_ASSETS_IN_ROW: 'CommonTwoAssetsInRowRecord',
    HORIZONTAL_HEADER: 'HorizontalBigHeaderRecord',
    TEXT_WITH_BREAK: 'CommonTextWithBreakRecord',
    TEXT_SMALL_IMAGE: 'CommonTextSmallImageRecord',
    CHECK_OTHERS: 'CheckothersModelRecord',
    DESCRIPTION_SLOGAN: 'CommonDescriptionSloganRecord',
    PROJECT_SCOPE: 'CommonProjectScopeRecord',
    PLEO_CONTEXT: 'CommonPleoContextRecord',
    VIDEO_BANNER: 'VideoBannerRecord',
    TEXT_MODULE: 'CommonTextModuleRecord',
    PROJECT_SLIDER: 'CommonProjectSliderRecord',
    FORM: 'ApplicationFormModuleRecord',
    PLEO_BRANCHES: 'DigitalPleoBranchesModuleRecord',
    QUOTE_BLOCK: 'QuoteBlockRecord',
    TEXT_CENTERED_BLOCK: 'TextCenteredBlockRecord',
    HEADER_DESCRIPTION_TWO_COLUMNS_BLOCK: 'HeaderDescriptionTwoColumnsBlockRecord',
    HEADER_DESCRIPTION_HALF_WIDTH_BLOCK: 'HeaderDescriptionHalfWidthBlockRecord',
    TEXT_BREAK: 'TextBreakRecord',
    TYPOGRAPHY_BIG: 'TypographyBigRecord',
    IMAGE_BREAK: 'ImageBreakRecord',
    STICKY_HEADING: 'StickyHeadingSingleItemRecord',
    PROJECT_CATEGORY: 'ProjectCategoryRecord',
    SINGLE_ASSET_BLOCK: 'SingleAssetBlockRecord',
    WHO_WE_WORKED_WITH: 'WhoWeWorkedWithModuleRecord',
    QUICK_CONTACT :'CommonQuickContactRecord',
    DESCRIPTION_LOGO_PLEO: 'DescriptionLinePleoModuleRecord',
    COMMON_BLOB_SLOGAN: 'CommonBlobSloganRecord',
    FEATURED_PROJECTS: 'FeaturedProjectsModuleRecord',
    HOW_WE_CREATE_STORY: 'HowWeCreateStoryModuleRecord',
    NEWS_THUMBNAILS: 'NewsThumbnailRecord',
    HOW_WE_WORK_MODULE: 'HowWeWorkModuleRecord',
    SERVICES_CARDS : 'ServicesCardsModuleRecord',
    TILES_WITH_DESCRIPTION: 'TilesWithDescriptionRecord',
    AREAS_OF_EXPERTISE: 'CommonAreasOfExpertiseRecord',
    COMPETENCES_PAGE_RECORD: 'CommonCompetencesPageRecord',
    SERVICE_PAGE_RECORD: 'CommonServicePageRecord',
    TOOL_RECORD :'ToolRecord',
    PROJECT_TECHNOLOGY_RECORD: 'ProjectTechnologyRecord',
    TILES_MODULE: 'TilesModuleRecord',
    TECHNOLOGIES_TILES: 'CommonTechnologiesTileRecord',
    BIG_HEADER_DESCRIPTION_TWO_COLUMNS_BLOCK:'TypographyBigHeaderDescriptionTwoColumnsBlockRecord',
    BENEFITS_MODULE:'CommonBenefitsModuleRecord',
    CLIENTS_ABOUT_US_RECORD: 'CommonClientsAboutUsModuleRecord',
    CHECK_OTHER_SERVICES: 'CheckOtherServiceRecord',
    NUMBERS_RECORD :'CommonNumbersModuleRecord',
    PRODUCTS_GRID: 'ProductsGridRecord',
    CAREER_MODULE :'CommonCareerModuleRecord',
    JOB_OFFER_FORM: 'CommonJobOfferFormRecord',
    GROUP_SINGLE_PAGE_RECORD: 'GroupSinglePageRecord',
    DIGITAL_SINGLE_PROJECT_RECORD: 'DigitalSingleProjectRecord',
    DESIGN_SINGLE_PROJECT_RECORD: 'DesignSingleProjectRecord',
    GROUP_BLOG_PAGE_RECORD: 'CommonBlogPageRecord',
    HEADER_DESCRIPTION_BUTTON_BANNER_RECORD :'HeaderDescriptionButtonBannerRecord',
    STORY_MODULE :'StoryModuleRecord',
    TYPOGRAPHY_MEDIUM_TEXT: 'TypographyMediumTextRecord'
}

export const PADDING = {
    EXTRA_SMALL: 'extra-small',
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    EXTRA_LARGE: 'extra-large',
    NONE: 'none'
}
export const LOCALES = {
    PL: 'pl',
    EN: 'en',
};
export const EASE = [0.16, 1, 0.3, 1];
export const CLOSE_MENU_ANIMATION_DURATION = 0 // miliseconds

export const PORTAL_LENS_RADIUS = 3; //units of 3D space

export const CURSOR_ASSET_SIZE = {
    LARGE: 'large',
    SMALL: 'small'
}

export const MIME_TYPE = {
    video: 'video/mp4',
    image: 'image/jpeg'
}

export const PLEO_TYPE = {
    DIGITAL: 'digital',
    DESIGN: 'design',
    GROUP: 'group'
}
